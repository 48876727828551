<script setup>
import { useBible } from "~/composables/useBible";

const emit = defineEmits(["select", "goback"]);

const { biblesColl } = useBible();
</script>
<template>
  <v-navigation-header
    class="sticky mx-2"
    @goback="emit('goback')"
    label="Переклад"
  ></v-navigation-header>
  <div class="mt-4">
    <div
      v-for="bibleModel in biblesColl"
      @click="emit('select', { bibleCode: bibleModel?.bibleCode })"
      class="bible-transl"
    >
      <div class="bible-shortname">{{ bibleModel?.bibleShortName }}</div>
      <div>{{ bibleModel?.bibleName }}</div>
    </div>
  </div>
</template>
<style scoped>
.bible-transl {
  padding: 15px 20px;
  font-size: 20px;
  border-radius: 5px;
  line-height: 20px;
}

.bible-transl:hover {
  background: var(--root-green);
  color: var(--root-white);
  cursor: pointer;
}

.bible-shortname {
  font-size: 18px;
  font-weight: 600;
}
</style>
